.App {
  text-align: center;
  font-family: 'Crimson Text', serif;
}

a {
  text-decoration: none;
  color: black;
}

.side a {
  font-size: 1.15rem;
}

.side-active a {
  color: #ec4c4c;
  font-weight: bold;
  font-size: 1.25rem;
}

.students-container h1 {
  font-size: 3rem;
}

.student-header {
  position: sticky;
  top: 0;
}

.placement {
  width: 15%;
}

.student-image img {
  width: 300px;
  border: 1px solid green;
  padding: 1%;}

.icon img {
  width: 2%;
}

.req {
  color: red;
  font-weight: bold;
  font-size: 0.9rem;
  
}